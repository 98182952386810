import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './pageStyles/circuitguard-support-materials.scss'

const DocumentationPage = () => {
  return (
    <Layout>
      <Seo title="circuitGuard&trade; Support Materials" />
      <style type="text/css" dangerouslySetInnerHTML={{ __html: "html.hs-messages-widgcirc-open et-open.hs-messages-mobile,html.hs-messages-widgcirc-open et-open.hs-messages-mobile body{overflow:hidden!important;position:relative!important}html.hs-messages-widgcirc-open et-open.hs-messages-mobile body{height:100%!important;margin:0!important}#hubspot-messages-iframe-container{display:initial!important;z-index:2147483647;position:fixed!important;bottom:0!important}#hubspot-messages-iframe-container.widgcirc-align-left et-align-left{left:0!important}#hubspot-messages-iframe-container.widgcirc-align-right et-align-right{right:0!important}#hubspot-messages-iframe-container.internal{z-index:1016}#hubspot-messages-iframe-container.internal iframe{min-width:108px}#hubspot-messages-iframe-container .shadow-container{display:initial!important;z-index:-1;position:absolute;width:0;height:0;bottom:0;content:\"\"}#hubspot-messages-iframe-container .shadow-container.internal{display:none!important}#hubspot-messages-iframe-container .shadow-container.active{width:400px;height:400px}#hubspot-messages-iframe-container iframe{display:initial!important;width:100%!important;height:100%!important;border:none!important;position:absolute!important;bottom:0!important;right:0!important;background:transparent!important}" }} />
      <div id="page-container" style={{ overflowY: 'hidden' }}>
        <div id="circ-boc et-boc" className="circ-boc et-boc">
          <div id="circ-main-area et-main-area">
            <div id="main-content">
              <article id="post-185" className="post-185 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="circ-l et-l circ-l--post et-l--post">
                    <div className="circ_builder_inner_content et_builder_inner_content circ_pb_gutters3 et_pb_gutters3">
                      <div className="circ_pb_section et_pb_section circ_pb_section_7 et_pb_section_7 circ_pb_with_background et_pb_with_background circ_section_regular et_section_regular section_has_divider circ_pb_bottom_divider et_pb_bottom_divider circ_pb_top_divider et_pb_top_divider optml-bg-lazyloaded circ_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: '168px' }}>
                        <div className="circ_pb_row et_pb_row circ_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                          <div className="circ_pb_column et_pb_column circ_pb_column_4_4 et_pb_column_4_4 circ_pb_column_11 et_pb_column_11  circ_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough circ-last-child et-last-child">
                            <div className="circ_pb_module et_pb_module circ_pb_text et_pb_text circ_pb_text_8 et_pb_text_8 circ_pb_text_align_center et_pb_text_align_center circ_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="circ_pb_text_inner et_pb_text_inner circ_title">circuitGuard&trade; Support Materials</div>
                            </div>
                          </div>
                        </div>
                        <div className="circ_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
                      </div>
                      <div className="circ_pb_section et_pb_section circ_pb_section_8 et_pb_section_8 circ_section_regular et_section_regular">
                        <div className="circ_pb_row et_pb_row circ_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                          <div className="circ_pb_column et_pb_column circ_pb_column_4_4 et_pb_column_4_4 circ_pb_column_12 et_pb_column_12  circ_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough circ-last-child et-last-child">
                            <div className="circ_pb_module et_pb_module circ_pb_text et_pb_text circ_pb_text_9 et_pb_text_9 circ_pb_text_align_left et_pb_text_align_left circ_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="circ_pb_text_inner et_pb_text_inner"><h2>Click any of the following links to download the PDF.</h2>
                                <p><a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133793/Arc_Medical/Multiple-Patient-Use-of-Anesthesia-Circuit-POM.pdf" target="_blank" rel="noopener noreferrer">Legal Use of a single patient use breathing circuit for multiple patients</a><br />
                                  <a href="https://res.cloudinary.com/dthskrjhy/raw/upload/v1629133901/Arc_Medical/SAMPLE-Protocol-and-Procedure.docx" target="_blank" rel="noopener noreferrer">Sample Protocol and Procedure for use of a single patient use breathing circuit for multiple patients- Download</a><br />
                                  <a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629133961/Arc_Medical/K090738-Indications-for-Use.pdf" target="_blank" rel="noopener noreferrer">circuitGuard&trade; – 510(k) Information</a><br />
                                  <a href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629128953/Arc_Medical/History-of-Single-Patient-Use-Anesthesia-Breathing-Circuits-in-USA-2021.pdf">Why the USA Uses Disposable Anesthesia Breathing Circuits</a><br />
                                  <a href="/filtration-studies">Filtration Studies</a></p>
                                <p><b>circuitGuard&trade; Requested Information</b><br />This information is provided upon request by and to health care professionals for educational and scientific purposes only. ARC Medical does not endorse or support any claims made beyond those provided indications and/or use instructions accompanying our products.</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DocumentationPage